export function useScrollDepth({ scrollTop }) {
  const [scrollDepth, setScrollDepth] = React.useState(false)

  React.useEffect(
    () => {
      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }

      function handleScroll() {
        const offset = window.scrollY
        const stickyThreshold = scrollTop

        setScrollDepth(offset > stickyThreshold)
      }
    },
    [scrollTop, scrollDepth]
  )

  return scrollDepth
}
